<template>
  <moe-page title="分类列表">
    <moe-table ref="categoryListTreeTable" url="/shop/goods/categoryListTree" :params="categoryParams" :number-show="false" row-key="id" children="childrens">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/category/add')">添加分类</el-button>
      </template>
      <el-table-column prop="name" label="分类名称" min-width="130" :show-overflow-tooltip="true" />
      <el-table-column label="分类级别">
        <template slot-scope="{ row }">
          {{ levelOptions[row.level] }}
        </template>
      </el-table-column>
      <el-table-column prop="empty" label="分类图片" width="130">
        <template slot-scope="{ row }">
          <moe-image v-if="row.coverUrl" :src="row.coverUrl" width="70px" height="70px" />
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="130" />
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="spec" label="规格" :show-overflow-tooltip="true" />
      <el-table-column prop="status" label="状态" min-width="150">
        <template slot-scope="{ row }">
          <moe-switch @change="(e) => req_shelfCategory(row, e)" :activeValue="1" :inactiveValue="2" v-model="row.status" activeText="启用" inactiveText="禁用"></moe-switch>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="130" />
      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/category/detail?id=${row.id}`)">详情</el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/category/amend?id=${row.id}`)">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="categoryDelete(row)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'Category',
  data() {
    return {
      categoryParams: {
        pageNum: 1,   //当前页
      },
      levelOptions: {
        1: '一级分类',
        2: '二级分类',
        3: '三级分类'
      }
    }
  },
  methods: {
    /**
      * 分类搜索
      **/
    activitySearch(isSearch) {
      if (!isSearch) {
        this.categoryParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
        };
      }

      //刷新商品表格
      this.$refs.categoryListTreeTable.searchData();
    },

    /** 更新分类状态 */
    req_shelfCategory({ id }, status) {
      this.$moe_api.GOODS_API.shelfCategory({ ids: id, status }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.activitySearch(true);
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    categoryDelete(item) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${item.name}”吗？`, () => {
        this.$moe_api.GOODS_API.delCategory({ ids: item.id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('删除成功');
            this.$refs.categoryListTreeTable.searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>